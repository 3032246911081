import '../libs/weather/jquery.simpleWeather.js';
import '../../modules/media/svg/theme/js/SVGInject';

class Site {
    public static discover($parent?: JQuery): void {
        (<any> $('.svg-inject', $parent)).svgInject();
    }

    public static init(): void {
        (<any> window).Site = Site;

        $(() => {
            this.discover();
        });
    }
}

Site.init();
